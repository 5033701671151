import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Timestamp } from 'firebase/firestore';
import { firstValueFrom, Observable } from 'rxjs';

import UniqueEntityID from '../../../core/domain/unique_entity_id';
import { PeriodBarResult } from '../../../ui/components/period-bar/period-bar.component';
import { PatientId } from '../../patient/domain/patient';
import {
  BillLineDescription,
  BillLineDescriptionProps,
} from '../domain/bill/bill-line-description';
import { DietitianId } from '../domain/dietitian';
import { Estimate, EstimateStatus } from '../domain/estimate/estimate';
import { EstimateNotFoundException } from '../domain/estimate/estimate-exception';
import { EstimateLine } from '../domain/estimate/estimate-line';

export interface EstimateLineSchema {
  estimateId: string;
  descriptionId: string;
  comment: string | null;
  amount: number;
  date: string | null;
  createdAt: Timestamp | null;
  updatedAt: Timestamp | null;
}

export interface EstimateSchema {
  patientId: string;
  patientFullname: string;
  referencePrefix: string | null;
  reference: number | null;
  estimateAt: Timestamp | null;
  lines: EstimateLineSchema[];
  amountExclTax: number;
  amountInclTax: number;
  amountTax: number;
  rateTax: number;
  withTax: boolean;
  expireAt: Timestamp | null;
  createdAt: Timestamp | null;
  updatedAt: Timestamp | null;
  deleted: boolean;
  deletedAt: Timestamp | null;
  url: string | null;
  path: string | null;
  documentHasReady: boolean;
  status: EstimateStatus;
  transformedAt?: Timestamp | undefined;
}

export interface ResultTransform {
  estimateId: string;
  billId: string;
}

export interface ResultCalcTot {
  count: number;
  amountIncludingTax: number;
  amountExcludingTax: number;
}

@Injectable()
export class EstimateRepository {
  private readonly calcTotalEstimate: (
    data: unknown,
  ) => Observable<ResultCalcTot>;

  private readonly transformEstimateFromCloud: (
    data: unknown,
  ) => Observable<ResultTransform>;

  constructor(
    private firestore: AngularFirestore,
    private functions: AngularFireFunctions,
  ) {
    this.calcTotalEstimate = this.functions.httpsCallable<
      unknown,
      ResultCalcTot
    >('estimate-calcTotalEstimate');

    this.transformEstimateFromCloud = this.functions.httpsCallable<
      unknown,
      ResultTransform
    >('estimate-transformEstimate');
  }

  public toSchema(estimate: Estimate): EstimateSchema {
    return {
      patientId: estimate.patientId?.id.toString(),
      patientFullname: estimate.patientFullname,
      referencePrefix: estimate.referencePrefix ?? null,
      reference: estimate.reference ?? null,
      estimateAt: estimate.estimateAt
        ? Timestamp.fromDate(estimate.estimateAt)
        : null,
      lines: this.toLineSchema(estimate),
      amountExclTax: estimate.amountExclTax,
      amountInclTax: estimate.amountInclTax,
      amountTax: estimate.amountTax,
      rateTax: estimate.rateTax,
      withTax: estimate.withTax,
      expireAt:
        estimate.expireAt !== undefined
          ? Timestamp.fromDate(estimate.expireAt)
          : null,
      createdAt:
        estimate.createdAt !== undefined
          ? Timestamp.fromDate(estimate.createdAt)
          : Timestamp.now(),
      updatedAt: Timestamp.now(),
      deleted: estimate.deleted,
      deletedAt:
        estimate.deletedAt !== undefined
          ? Timestamp.fromDate(estimate.deletedAt)
          : null,
      url: estimate.url ?? null,
      path: estimate.path ?? null,
      documentHasReady: estimate.documentHasReady,
      status: estimate.status ?? EstimateStatus.EMITTED,
      transformedAt: estimate.transformedAt
        ? Timestamp.fromDate(estimate.transformedAt)
        : null,
    } as EstimateSchema;
  }

  fromSchema(
    schema: EstimateSchema,
    dietitianId: string,
    id?: string,
  ): Estimate {
    return Estimate.create(
      {
        dietitianId: DietitianId.create(new UniqueEntityID(dietitianId)),
        patientId: PatientId.create(new UniqueEntityID(schema.patientId)),
        patientFullname: schema.patientFullname,
        referencePrefix: schema.referencePrefix ?? undefined,
        reference: schema.reference ?? undefined,
        estimateAt: schema.estimateAt?.toDate() ?? undefined,
        lines: this.fromLineSchema(schema.lines),
        amountExclTax: schema.amountExclTax,
        amountInclTax: schema.amountInclTax,
        amountTax: schema.amountTax,
        rateTax: schema.rateTax,
        withTax: schema.withTax,
        expireAt: schema.expireAt?.toDate() ?? undefined,
        createdAt: schema.createdAt?.toDate() ?? undefined,
        updatedAt: schema.updatedAt?.toDate() ?? undefined,
        deleted: schema.deleted,
        deletedAt: schema.deletedAt?.toDate() ?? undefined,
        url: schema.url ?? undefined,
        path: schema.path ?? undefined,
        documentHasReady: schema.documentHasReady,
        status: schema.status ?? EstimateStatus.EMITTED,
      },
      new UniqueEntityID(id),
    );
  }

  async create(estimate: Estimate): Promise<Estimate> {
    const schema = this.toSchema(estimate);
    if (estimate.dietitianId) {
      // Reference
      const now = new Date();
      schema.referencePrefix =
        now.getFullYear().toString() +
        (now.getMonth() + 1 < 10
          ? '0' + (now.getMonth() + 1)
          : now.getMonth() + 1);
      const lastReference = await this.findLastReferenceByReferencePrefix(
        estimate.dietitianId.id.toString(),
        schema.referencePrefix,
      );
      if (lastReference && lastReference.length === 1) {
        schema.reference = lastReference[0].reference
          ? lastReference[0].reference + 1
          : null;
      } else {
        schema.reference = 1;
      }

      // Calcul des totaux
      const taxFactor = schema.withTax ? Number(schema.rateTax) / 100 : 0;
      for (const line of schema.lines) {
        schema.amountExclTax += Number(line.amount);
        schema.amountTax += Number(line.amount) * taxFactor;
        schema.amountInclTax +=
          Number(line.amount) + Number(line.amount) * taxFactor;
      }

      const ref = await this.collection(estimate.dietitianId.id.toString()).add(
        schema,
      );
      return this.fromSchema(
        schema,
        estimate.dietitianId.id.toString(),
        ref.id,
      );
    } else {
      return Promise.reject('Diététicien non identifié');
    }
  }

  async save(estimate: Estimate): Promise<Estimate> {
    const schema = this.toSchema(estimate);
    if (estimate.dietitianId) {
      // Reference
      if (!schema.referencePrefix && !schema.reference) {
        const now = new Date();
        schema.referencePrefix =
          now.getFullYear().toString() +
          (now.getMonth() + 1 < 10
            ? '0' + (now.getMonth() + 1)
            : now.getMonth() + 1);
        const lastReference = await this.findLastReferenceByReferencePrefix(
          estimate.dietitianId.id.toString(),
          schema.referencePrefix,
        );
        if (lastReference && lastReference.length === 1) {
          schema.reference = lastReference[0].reference
            ? lastReference[0].reference + 1
            : null;
        } else {
          schema.reference = 1;
        }
      }
      // Calcul des totaux
      schema.amountExclTax = 0;
      schema.amountTax = 0;
      schema.amountInclTax = 0;
      const taxFactor = schema.withTax ? Number(schema.rateTax) / 100 : 0;
      for (const line of schema.lines) {
        schema.amountExclTax += Number(line.amount);
        schema.amountTax += Number(line.amount) * taxFactor;
        schema.amountInclTax +=
          Number(line.amount) + Number(line.amount) * taxFactor;
      }

      const dietitianId = estimate.dietitianId.id.toString();
      await this.collection(dietitianId)
        .doc(estimate.estimateId.id.toString())
        .set(schema);
      return this.fromSchema(schema, dietitianId);
    } else {
      return Promise.reject('Diététicien non identifié');
    }
  }

  async load(dietitianId: string, estimateId: string): Promise<Estimate> {
    const snap = await firstValueFrom(
      this.collection(dietitianId).doc(estimateId).get(),
    );
    if (!snap.exists || snap.data == null) {
      throw new EstimateNotFoundException();
    }
    const schema = snap.data() as EstimateSchema;
    return this.fromSchema(schema, dietitianId, snap.id);
  }

  async findLastReferenceByReferencePrefix(
    dietitianId: string,
    referencePrefix: string | undefined,
  ) {
    let snap;
    if (referencePrefix) {
      snap = await firstValueFrom(
        this.collection(dietitianId, (ref) =>
          ref
            .where('referencePrefix', '==', referencePrefix)
            .orderBy('reference', 'desc')
            .limit(1),
        ).get(),
      );
    } else {
      snap = await firstValueFrom(
        this.collection(dietitianId, (ref) =>
          ref.orderBy('reference', 'desc').limit(1),
        ).get(),
      );
    }
    return snap.docs.map((doc) =>
      this.fromSchema(doc.data(), dietitianId, doc.id),
    );
  }

  async findAllForPatient(
    dietitianId: string,
    patientId: string,
  ): Promise<Estimate[]> {
    const snap = await firstValueFrom(
      this.collection(dietitianId, (ref) =>
        ref.where('patientId', '==', patientId),
      ).get(),
    );
    return snap.docs.map((doc) =>
      this.fromSchema(doc.data(), dietitianId, doc.id),
    );
  }

  async findAllExported(
    dietitianId: string,
    period: PeriodBarResult | undefined,
    status: EstimateStatus | undefined,
  ): Promise<string> {
    const snap = await firstValueFrom(
      this.collection(dietitianId, (ref) => {
        let qry = ref.orderBy('updatedAt', 'desc');
        if (status) {
          qry = qry.where('status', '==', status);
        }
        if (period) {
          if (period.start) {
            qry = qry.where('estimateAt', '>=', period.start.toDate());
          }
          if (period.end) {
            qry = qry.where('estimateAt', '<=', period.end.toDate());
          }
          if (period.start || period.end) {
            qry = qry.orderBy('estimateAt', 'desc');
          }
        }
        return qry;
      }).get(),
    );
    let result =
      'REFERENCE;PATIENT;DATE_DEVIS;DATE_EXPIRATION;MONTANT_HT;TVA;MONTANT_TTC;%TVA;STATUT;DATE_CREATION;DATE_MISE_A_JOUR;DATE_TRANSFORMATION;DATE_SUPPRESSION\r\n';
    for (const doc of snap.docs) {
      result +=
        (doc.data().referencePrefix ?? '') +
        '-' +
        (doc.data().reference?.toFixed().padStart(4, '0') ?? '') +
        ';' +
        doc.data().patientFullname +
        ';' +
        doc.data().estimateAt?.toDate().toISOString() +
        ';' +
        (doc.data().expireAt?.toDate().toISOString() ?? '-') +
        ';' +
        doc.data().amountExclTax +
        ';' +
        doc.data().amountTax +
        ';' +
        doc.data().amountInclTax +
        ';' +
        doc.data().rateTax +
        ';' +
        doc.data().status +
        ';' +
        (doc.data().createdAt?.toDate().toISOString() ?? '-') +
        ';' +
        (doc.data().updatedAt?.toDate().toISOString() ?? '-') +
        ';' +
        (doc.data().transformedAt?.toDate().toISOString() ?? '-') +
        ';' +
        (doc.data().deletedAt?.toDate().toISOString() ?? '-') +
        '\r\n';
    }
    return result;
  }

  async findAll(
    dietitianId: string,
    period: PeriodBarResult | undefined,
    status: EstimateStatus | undefined,
    lastId?: string | undefined,
  ) {
    if (lastId) {
      const last = await firstValueFrom(
        this.collection(dietitianId).doc(lastId).get(),
      );

      const snap = await firstValueFrom(
        this.collection(dietitianId, (ref) => {
          let qry = ref.where('deleted', '==', false);
          if (status) {
            qry = qry.where('status', '==', status);
          }
          if (period) {
            if (period.start) {
              qry = qry.where('estimateAt', '>=', period.start.toDate());
            }
            if (period.end) {
              qry = qry.where('estimateAt', '<=', period.end.toDate());
            }
            if (period.start || period.end) {
              qry = qry.orderBy('estimateAt', 'desc');
            }
          }
          qry = qry.orderBy('updatedAt', 'desc').startAfter(last).limit(30);
          return qry;
        }).get(),
      );
      return snap.docs.map((doc) =>
        this.fromSchema(doc.data(), dietitianId, doc.id),
      );
    } else {
      try {
        const snap = await firstValueFrom(
          this.collection(dietitianId, (ref) => {
            let qry = ref.where('deleted', '==', false);
            if (status) {
              qry = qry.where('status', '==', status);
            }
            if (period) {
              if (period.start) {
                qry = qry.where('estimateAt', '>=', period.start.toDate());
              }
              if (period.end) {
                qry = qry.where('estimateAt', '<=', period.end.toDate());
              }
              if (period.start || period.end) {
                qry = qry.orderBy('estimateAt', 'desc');
              }
            }
            qry = qry.orderBy('updatedAt', 'desc').limit(30);
            return qry;
          }).get(),
        );
        return snap.docs.map((doc) =>
          this.fromSchema(doc.data(), dietitianId, doc.id),
        );
      } catch (e) {
        console.log('❌', e);
        return [];
      }
    }
  }

  async calcTotalEstimateFromCloud(
    start: Date | undefined,
    end: Date | undefined,
    status: EstimateStatus | undefined,
  ): Promise<ResultCalcTot> {
    const data = {
      start,
      end,
      status,
    };
    return firstValueFrom(this.calcTotalEstimate(data));
  }

  async delete(estimate: Estimate): Promise<void> {
    if (estimate.dietitianId) {
      const schema = this.toSchema(estimate);
      schema.deleted = true;
      schema.deletedAt = Timestamp.now();
      const dietitianId = estimate.dietitianId.id.toString();
      await this.collection(dietitianId)
        .doc(estimate.estimateId.id.toString())
        .set(schema);
      this.fromSchema(schema, dietitianId);
    }
  }

  estimateValueChanges(
    dietitianId: string,
    estimateId: string,
  ): Observable<EstimateSchema | undefined> {
    return this.collection(dietitianId).doc(estimateId).valueChanges();
  }

  private collection(dietitianId: string, queryFn?: QueryFn) {
    return this.firestore
      .collection('dietitians')
      .doc(dietitianId)
      .collection<EstimateSchema>('estimates', queryFn);
  }

  private toLineSchema(estimate: Estimate): EstimateLineSchema[] {
    return estimate.lines.map((line) => {
      return {
        estimateId: estimate.id.toString(),
        descriptionId: line.description?.id.toString(),
        comment: line.comment ?? null,
        amount: line.amount,
        date: line.date ?? null,
        createdAt:
          line.createdAt !== undefined
            ? Timestamp.fromDate(line.createdAt)
            : Timestamp.now(),
        updatedAt: Timestamp.now(),
      } as EstimateLineSchema;
    });
  }

  private fromLineSchema(lines: EstimateLineSchema[]): EstimateLine[] {
    return lines.map((line) => {
      return EstimateLine.create({
        description: BillLineDescription.create(
          {} as BillLineDescriptionProps,
          new UniqueEntityID(line.descriptionId),
        ),
        comment: line.comment ?? undefined,
        amount: line.amount,
        date: line.date ?? undefined,
        createdAt: line.createdAt?.toDate() ?? undefined,
        updatedAt: line.updatedAt?.toDate() ?? undefined,
      });
    });
  }

  async transform(
    estimate: Estimate,
    dietitianId: string,
  ): Promise<ResultTransform> {
    const data = {
      estimateId: estimate.id.toString(),
      dietitianId,
    };
    return firstValueFrom(this.transformEstimateFromCloud(data));
  }
}
